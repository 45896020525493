@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  min-height: 100%;
}
body {
  font-family: "Montserrat Bold" !important;
  position: relative;
  overflow: hidden;
}
#root {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  overflow: hidden;
  margin: 0;
}
a {
  cursor: pointer;
}
/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  @apply tw-absolute tw-w-6 tw-h-6 tw-top-0 tw-bottom-0 tw-left-0 tw-border tw-bg-white tw-rounded-lg tw-mb-auto tw-mt-auto;
}
/* checked mark aspect */
[type="checkbox"]:checked + label:after {
  content: "✕";
  @apply tw-flex tw-absolute tw-items-center tw-w-6 tw-h-6 tw-px-1 tw-text-black tw-top-0 tw-bottom-0 tw-left-0 tw-mb-auto tw-mt-auto tw-font-bold tw-justify-center;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #efefef;
  border: none;
  padding: 1em 2em 1em 1em;
  font-size: 1em;
}
select::-ms-expand {
  display: none;
}

.select-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}
.select-wrapper:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 1.5rem;
  right: 0.75rem;
  border-top: 8px solid black;
  opacity: 0.5;
}

.profile-select-wrapper:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
}
.profile-select-wrapper:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 0.9rem;
  right: 0.75rem;
  border-top: 8px solid black;
  opacity: 0.5;
}

.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9000;
  top: 0;
  min-height: 100%;
}

@media only screen and (min-width: 768px) {
  .str-chat.str-chat-channel-list,
  .str-chat.str-chat-channel {
    height: 80vh;
  }
}

.change-my-color {
  fill: rgb(188, 40, 40);
}

circle {
  stroke: rgb(188, 40, 40); 
  stroke-width: 2px;
  stroke-opacity: 1;
}

.pulse {
  fill: rgb(188, 40, 40);
  fill-opacity: 0;
  transform-origin: 50% 50%;
  animation-duration: 2s;
  animation-name: pulse;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  from {
    stroke-width: 1px;
    stroke-opacity: 1;
    transform: scale(0.3);
  }
  to {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(2);
  }
}
